const formatter = new Intl.NumberFormat('en-US');

let timer;

$(document).on('keydown', '.number-format', function (event) {
  //console.log(event.key);
  if (event.altKey || event.shiftKey || event.ctrlKey) return;

  if (event.key.length < 2 && event.key.match(/[a-zA-Z]/g))
    event.preventDefault();

  if (event.key.match(/[0-9]/g) || event.key === 'Backspace') {
    const _this = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(function () {
      $(_this).val(function (index, value) {
        if (value.length < 1) return value;
        const number = parseFloat(value.replace(/,/g, ''));
        return number === NaN ? value : formatter.format(number);
      });
      timer = undefined;
    }, 600);
  }
});


$(document).on('submit', 'form', function (event) {
  // console.log('asdsd');
  // event.preventDefault();
  $(this).find('.number-format').val(function (i, value) {
    return parseFloat(value.replace(/,/g, ''));
  });
});
